import { Collapse, Dialog, DialogContent, DialogTitle, Grid, IconButton } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CloseIcon from '@material-ui/icons/Close';
import cn from 'classnames';
import moment from 'moment';
import React from 'react';
import { FaAddressBook, FaAngleDown, FaAngleUp } from 'react-icons/fa';
import { IoMdCalendar, IoMdStopwatch } from 'react-icons/io';
import { MdInsertDriveFile } from 'react-icons/md';
import { connect } from 'react-redux';
import { muiResponsive } from 'src/appConfig/muiTheme';
import PageContentContainer from 'src/components/PageContentContainer';
import { IRootState } from 'src/redux/rootReducer';
import { SessionLogDetail } from 'src/redux/sessionLogsRedux/types';
import { getDateDisplay, TimeFormat12 } from 'src/utils/momentUtils';
import { getSupportTypeName } from 'src/utils/nameUtils';
import { isEmpty } from 'src/validations';
import { ActionsButton } from '../components/ActionsButtonPopover';
import { getReferredByName, CONSTANT_SUBMISSION_SL, getAbsentReasonName } from './helper';
import './styles.scss';

type Props = ReturnType<typeof mapState> &
  typeof mapDispatch & {
    service: SessionLogDetail;
    onClose: () => void;

    onEdit?: (service: SessionLogDetail) => void;
    onCopy?: (service: SessionLogDetail) => void;
    onEnd?: (service: SessionLogDetail) => void;
    onDelete?: (service: SessionLogDetail) => void;
    loading?: boolean;
  };

const clsPrefix = 'submission-history-detail';

type Row =
  | 'break'
  | [label: string, content: React.ReactNode]
  | [label: string, content: React.ReactNode, vertical: boolean];
const InfoSession = ({
  title = null,
  icon = null,
  rows = [],
  omitMargin = false,
  children,
  breakLine = false,
}: {
  rows?: Row[];
  title?: string;
  icon?: React.ReactNode;
  omitMargin?: boolean;
  children?: React.ReactNode;
  breakLine?: boolean;
}) => {
  const isMobile = useMediaQuery(muiResponsive.MOBILE);
  const [collapsed, setCollapsed] = React.useState<boolean>(false);

  return (
    <div className={cn(`${clsPrefix}-session-info`, omitMargin && 'omit-margin', !title && 'no-title')}>
      {title && (
        <button
          className={`${clsPrefix}-session-info-title`}
          {...(isMobile ? { onClick: () => setCollapsed(collapsed => !collapsed) } : undefined)}>
          <div>
            {icon}
            <h6>{title}</h6>
          </div>
          {isMobile && (
            <IconButton className={`${clsPrefix}-session-info-title-collapse-button`}>
              {collapsed ? <FaAngleDown /> : <FaAngleUp />}
            </IconButton>
          )}
        </button>
      )}
      <Collapse in={!collapsed}>
        {rows.map((row, i) => {
          if (row === 'break') {
            return <div key={`${title}-break-${i}`} className={`${clsPrefix}-session-info-break`} />;
          }

          return (
            <div
              key={`${title || rows[0]}-${i}`}
              className={cn(
                `${clsPrefix}-session-info-row`,
                row.length > 2 && row[2] && 'vertical',
                breakLine ? `multiple-mobile` : '',
              )}>
              <span className={`${clsPrefix}-session-info-label`}>{row[0]}</span>
              {row[1] && <span className={`${clsPrefix}-session-info-content`}>{row[1]}</span>}
            </div>
          );
        })}
        {children}
      </Collapse>
    </div>
  );
};

const ServiceDetailView: React.VFC<Props> = ({ service, onClose, onEdit, onCopy, onEnd, onDelete, loading }) => {
  const handleTitleActionClicked = callback => () => callback(service);

  const fullScreen = useMediaQuery(muiResponsive.MOBILE);

  const studentInfo = () => {
    const studentFullName = `${service.studentFirstName} ${service.studentLastName}`;
    return (
      <InfoSession
        title="Student Info"
        icon={<FaAddressBook />}
        rows={[
          ['Name', `${studentFullName}`],
          ['ID', `${service.studentExtId}`],
          ['School', `${service.studentSchoolName} (${service.schoolCode})`],
          ['Gender', `${service.gender}`],
          ['Grade', `${service.grade}`],
          ['Birth Date', `${getDateDisplay(service.birthDate)}`],
        ]}
      />
    );
  };

  const relatedServiceInfo = () => {
    const rows = [];
    rows.push([
      'Service Type',
      `${service.supportTypeName ? service.supportTypeName : getSupportTypeName(service.supportType)}`,
    ]);
    rows.push(['Projected Start Date', `${getDateDisplay(service.serviceStartDate)}`]);
    rows.push(['Projected End Date', `${getDateDisplay(service.serviceEndDate)}`]);
    rows.push(['Frequency', `${!service.frequency || service.frequency === 'null' ? '' : service.frequency}`]);
    let providerInfo = `${service.providerFullName} ${
      service.providerCredential !== null ? ` / ${service.providerCredential} ` : ''
    }`;
    !isEmpty(service.serviceEsy) && rows.push(['ESY', `${service.serviceEsy ? 'Yes' : 'No'}`]);
    rows.push(['Provider', `${providerInfo}`]);
    // rows.push(['Target', `-`]);
    return <InfoSession title="Related Service Info" icon={<MdInsertDriveFile />} rows={rows} />;
  };

  const tieredServiceInfo = () => (
    <InfoSession
      title="Tiered Service Info"
      icon={<MdInsertDriveFile />}
      rows={[
        ['Tier', `${service.supportTypeName ? service.supportTypeName.substr(-1) : ''}`],
        ['Start Date', `${service.serviceStartDate ? getDateDisplay(service.serviceStartDate) : ''}`],
        [`${service.serviceActiveFlag ? 'Projected ' : ''} End Date`, `${service.serviceEndDate ? getDateDisplay(service.serviceEndDate) : ''}`],
        ['Frequency', `${isEmpty(service.frequency) ? '' : service.frequency}`],
        [
          'Provider',
          `${service.providerFullName} ${service.providerPositionName ? `/ ${service.providerPositionName}` : ''}`,
        ],
      ]}
    />
  );

  const sessionInfo = () => {
    const rows = [];
    rows.push(['Session Date', `${getDateDisplay(service.sessionDate)}`]);
    service.supportType === CONSTANT_SUBMISSION_SL.unsched &&
      rows.push([
        'Provider',
        `${service.providerFullName} ${service.providerCredential ? `/ ${service.providerCredential}` : ''}`,
      ]);
    rows.push([
      'Start Time (Length)',
      `${!isEmpty(service.sessionTime) ? moment(service.sessionTime, 'HH:mm:ss').format(TimeFormat12) : ''} (${
        service.studentAbsentFlag ? 'Student Not Available' : `${service.sessionMinutes} min${service.sessionMinutes > 1 ? 's' : ''}`
      })`,
      true,
    ]);
    service.contactType &&
      rows.push(['Service Delivered', `${service.contactTypeName}`, true]);
    service.contactType &&
      rows.push(['Session Type', `${service.sessionTypeName}`, true]);
    service.referredBy &&
      rows.push([
        'Referred By',
        `${getReferredByName(service.referredBy)} ${
          service.referredByOther !== null ? ` - ${service.referredByOther}` : ''
        }`,
      ]);
    service.locationName &&
      rows.push([
        'Location',
        `${service.locationName ? service.locationName : ''} ${
          service.locationDetails ? ` - ${service.locationDetails}` : ''
        }`,
      ]);
    service.groupSize && rows.push(['Group Size', `${service.groupSize ? service.groupSize : ''}`]);
    service.deliveryModeName &&
      rows.push(['Delivery Mode', `${service.deliveryModeName ? service.deliveryModeName : ''}`, true]);
    service.directNonDirectName &&
      rows.push(['Direct vs. Non-Direct', `${service.directNonDirectName ? service.directNonDirectName : ''}`, true]);
    service.roomName && rows.push(['Classroom Number', `${service.roomName}`, true]);
    service.studentAbsentReason &&
      rows.push(['Absent Reason', `${getAbsentReasonName(service.studentAbsentReason)}`, true]);

    return <InfoSession title="Session Info" icon={<IoMdCalendar />} rows={rows} />;
  };

  const targetsInfo = (target: string, concernTreatment: string, practice: string, outcome: string, note: string) => (
    <InfoSession
      rows={[
        ['Target', target ? target : '', true],
        ['Concern/Treatment', concernTreatment || '', true],
        ['Practice Element', practice ? practice : '', true],
        ['Outcome', !outcome || outcome === 'null' ? '' : outcome, true],
        ['Session Note', !note || note === 'null' ? '' : note, true],
      ]}
      breakLine={true}
    />
  );

  const bimasInfo = (progressMeasure: string, measure: string, score: string) => (
    <InfoSession
      rows={[
        ['Progress Measure', progressMeasure ? progressMeasure : '', true],
        ['Measure Description', measure ? measure : '', true],
        ['Score', !score || score === 'null' ? '' : score, true],
      ]}
      breakLine={true}
    />
  );

  const comments = () => <InfoSession rows={[['Follow-up/Other', service.comments, true]]} />;

  const getAuditLog = logs => {
    const rows: Row[] = [];
    logs.forEach((log, index) => {
      // Created 03/01/2021  09:05 AM
      const row: Row = [
        `${log.fullName}`,
        `\n${log.updateName} ${moment(log.updatedDate).format('MM/DD/YYYY h:mm A')}`,
      ];
      rows.push(row);
    });
    return rows;
  };

  const auditLogs = () => (
    <InfoSession title="Audit Log" icon={<IoMdStopwatch />} rows={getAuditLog(service.logs)} breakLine={true} />
  );

  return (
    <Dialog
      open={true}
      onClose={onClose}
      fullScreen={fullScreen}
      fullWidth
      maxWidth="md"
      scroll="paper"
      className={`${clsPrefix}-dialog`}>
      <DialogTitle className={`${clsPrefix}-dialog-title`} disableTypography>
        <h6>View Submission</h6>
        {service.canEdit && (
          <div className={`${clsPrefix}-dialog-actions-container`}>
            <Grid container spacing={2}>
              <ActionsButton
                renderAsMenu={false}
                asGridItem={true}
                onEdit={handleTitleActionClicked(onEdit)}
                onCopy={handleTitleActionClicked(onCopy)}
                onDelete={handleTitleActionClicked(onDelete)}
              />
            </Grid>
          </div>
        )}
        <IconButton className={`${clsPrefix}-dialog-icon`} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <PageContentContainer loading={loading}>
          <>
            {studentInfo()}

            {service.supportType === CONSTANT_SUBMISSION_SL.service && relatedServiceInfo()}
            {service.supportType === CONSTANT_SUBMISSION_SL.sbbhtiered && tieredServiceInfo()}
            {sessionInfo()}
            {service.TPO.map((TPO, index) =>
              targetsInfo(
                service.formVersion ? (TPO.targetOther || TPO.target) : `${TPO.targetArea}: ${TPO.targetOther || TPO.target}`,
                TPO.concernTreatmentOther || TPO.concernTreatment,
                service.formVersion ? (TPO.practiceOther || TPO.practiceElement): `${TPO.practiceArea}: ${TPO.practiceOther || TPO.practiceElement}`,
                `${TPO.outcome}`,
                `${TPO.notes}`,
              ),
            )}
            {service.BIMAS.map((BIMAS, index) =>
              bimasInfo(
                `${BIMAS.measureCategory ? `${BIMAS.measureCategory}` : ''}`,
                `${BIMAS.measure ? `${BIMAS.measure}` : '' }`,
                `${BIMAS.score}`,
              ),
            )}
            {service.comments && comments()}
            {auditLogs()}
          </>
        </PageContentContainer>
      </DialogContent>
    </Dialog>
  );
};

const mapState = (state: IRootState) => {
  const { selectedLog } = state.sessionLogs.searchLogs;
  return { loading: selectedLog.loading };
};

const mapDispatch = {};

export default connect(mapState, mapDispatch)(ServiceDetailView);
