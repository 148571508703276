import { ApiResponse } from 'apisauce';
import { ProviderName, ServiceLocation } from '../schedulerRedux/types';
import { ApiResponse as ApiStatusWrapperResponse } from '../types';

export type RecentLogType = {
  sessionId: number;
  providerUserId: number;
  studentId: number;
  sessionDate: string;
  sessionTime: string;
  sessionMinutes: number;
  studentFirstName: string;
  studentLastName: string;
  studentExtId: string;
  schoolCode: string;
  studentSchoolName: string;
  canEdit: boolean;
  supportEndDate: string;
  supportStartDate: string;
  supportType: string;
  supportTypeName: string;
  grade: string;
};

export type RecentLogsType = {
  date: string;
  logs: RecentLogType[];
};

export type GetEditableStudentsRequest = {
  sessionDate: string;
  providerUserId: number;
  searchUnsched?: 0 | 1;
  searchService?: 0 | 1;
  searchTiered?: 0 | 1;
  lastName?: string;
  firstName?: string;
  schoolId?: number;
  includeInactiveStudents?: 0 | 1;
  includeInactiveSupports?: 0 | 1;
  itemsPerPage?: number;
  pageNumber?: number;
};

export type EditableStudentsType = {
  studentId: number;
  studentExtId: string;
  lastName: string;
  firstName: string;
  schoolCode: string;
  schoolName: string;
  birthDate: string;
  gender: string;
  grade: string;
  totalCount?: number;
  supportType?: string;
  supportId?: number;
  supportName?: string;
  providerId: number;
  startDate?: string;
  endDate?: string;
  frequency?: string;
  primaryTargetArea?: string;
  supportActiveFlag?: boolean;
  serviceActiveFlag?: boolean;
  esy?: boolean;
};

export type TypeDefault = {
  id: number;
  value: string;
};

export type TypeStringDefault = {
  id: string;
  value: string;
};

export const SupportTypeIOptionIndex = {
  service: 0,
  sbbhtiered: 1,
  unsched: 2,
};

export const SupportTypeOptions: TypeStringDefault[] = [
  { id: 'service', value: 'Related Service' },
  { id: 'sbbhtiered', value: 'Tiered Service' },
  { id: 'unsched', value: 'Non-caseload' },
];

// Add Session Template
export type AddSessionTemplateType = {
  provider?: ProviderName;
  credentialName?: string;
  primaryMethodOfContact?: string;
  sessionType?: string;
  sessionDate?: string;
  startTime?: string;
  endTime?: string;
  sessionLength?: string;
  groupSize?: string;
  location?: ServiceLocation;
  locationInput?: string;
  locationDetails?: string;
  deliveryMode?: string;
  directNonDirect?: string;
  roomName?: string;
  targets?: {
    tpoId: string;
    id: string;
    needDescription: boolean;
    description?: string;
    target: string;
    targetFullName?: string;
  }[];
  concernTreatments?: {
    id: string;
    needDescription: boolean;
    description?: string;
    concernTreatment: string;
  }[];
  practiceElements?: { id: string; needDescription: boolean; description?: string; practiceElement: string }[];
  progressMeasures?: {
    id: string;
    measureCategoryCode: string;
    measureId: number;
    measureCategory: string;
    measure: string;
    measureOther: string;
    score: string;
  }[];
  behaviorMeasures?: { id: string; measureId: number; measureCategoryCode: string; measure: string }[];
  comments?: string;
  formVersion?: string;
};

// Add Session Form
export type AddSessionLogType = {
  sessionId?: number;
  student: EditableStudentsType;
  hStudentId: number;
  studentNotAvailable: boolean;
  supportTypeId?: number;
  reasonStudentNotAvailable: string;
  provider: ProviderName;
  providerId: number;
  providerHUserId: number;
  credentialName?: string;
  primaryMethodOfContact: string;
  sessionType: string | null;
  referredBy: string | null;
  referredOtherDescription?: string | null;
  sessionDate: string;
  startTime: string;
  endTime: string;
  sessionLength: string;
  groupSize: string;
  location: ServiceLocation;
  locationDetails: string;
  deliveryMode: string;
  directNonDirect: string;
  roomName?: string | null;
  targets: { tpoId: string; id: string; needDescription: boolean; description?: string; target: string }[];
  concernTreatments: { id: string; needDescription: boolean; description?: string; concernTreatment: string }[];
  outcomes: number[];
  practiceElements: { id: string; needDescription: boolean; description?: string; practiceElement: string }[];
  progressMeasures: {
    id: string;
    measureCategoryCode: string;
    measureId: number;
    measureCategory: string;
    measure: string;
    measureOther: string;
    score: string;
  }[];
  behaviorMeasures: { id: string; measureId: number; measureCategoryCode: string; measure: string }[];
  notes: string[];
  licenseAttestUserId: number;
  licenseAttestName: string;
  licenseAttestDate: string;
  comments: string;
  formVersion?: string;
};

export type AddSessionLogFormProgress = {
  studentId: string | number;
  formValues: AddSessionLogType;
  completed?: boolean;
};

export type SessionLogCreateResponse = ApiResponse<ApiStatusWrapperResponse<SessionLogDetail>>;

export type CreateSessionLogPayload = {
  studentId: number;
  hStudentId: number;
  providerUserId: number;
  providerHUserId: number;
  // 'sbbhtiered' | 'unsched' | 'service'
  supportType: string;
  supportTypeId?: number;
  supportId: number;
  providerId: number;
  /**<Date (YYYY-MM-DD)> */
  sessionDate: string;
  // Time: "HH:mm:ss"
  sessionTime: string;
  sessionMinutes: number;
  studentAbsentFlag: 0 | 1;
  studentAbsentReason: string | null; // 'absent' | 'schoolEvent' | 'paperpacketprovided'
  contactType: string; //  'CONSUL' | 'CRISIS' | 'GROUP' | 'IN-CLASS' | 'INDIV' | 'OBS' | 'PARENT'
  sessionType: string; //  'SCHED' | 'WALKIN'
  referredBy: string | null; // 'SELF' | 'TEACHER' | 'ADMIN' | 'PARENT' | 'OTHER' | null
  referredByOther: string | null;
  groupSize: number | null;
  locationId: number;
  locationDetails: string;
  deliveryMode: string; // 'inperson' | 'online' | 'teleph' | 'teleprac' | 'virtual'
  directNonDirect: string; // 'PR' | 'NPR'
  roomName: string | null;
  comments: string | null;
  medicaidFlag: 0 | 1;
  medicaidMinutes: number | null;
  licenseAttestUserId: number | null;
  licenseAttestName: string | null;
  licenseAttestDate: string | null;
  logs: {
    comment: string | null;
  }[];
  TPO?: {
    targetId: number;
    targetOther: string;
    target: string;
    targetFullName?: string;
    concernTreatmentId: number;
    concernTreatmentOther: string;
    concernTreatment: string;
    practiceId: number;
    practiceOther: string;
    practiceFullName?: string;
    practiceElement: string;
    outcomeId: number;
    notes: string;
  }[];
  BIMAS?: {
    id: number;
    measureCategoryCode: string;
    measureId: number;
    measureOther: string;
    score: string;
  }[];
};

export type UpdateSessionLogPayload = {
  sessionId?: number;
  studentId: number;
  hStudentId: number;
  providerUserId: number;
  providerHUserId: number;
  supportType: string; // 'sbbhtiered' | 'unsched' | 'service'
  supportTypeId: number;
  supportId: number;
  providerId: number;
  sessionDate: string /**<Date (YYYY-MM-DD)> */;
  sessionTime: string; // Time: "HH:mm:ss"
  sessionMinutes: number;
  studentAbsentFlag: 0 | 1;
  studentAbsentReason: string | null; // 'absent' | 'schoolEvent' | 'paperpacketprovided'
  contactType: string; //  'CONSUL' | 'CRISIS' | 'GROUP' | 'IN-CLASS' | 'INDIV' | 'OBS' | 'PARENT'
  sessionType: string; // 'SCHED' | 'WALKIN'
  referredBy: string | null; // 'SELF' | 'TEACHER' | 'ADMIN' | 'PARENT' | 'OTHER' | null
  referredByOther: string | null;
  groupSize: number | null;
  locationId: number;
  locationDetails: string;
  deliveryMode: string; // 'inperson' | 'online' | 'teleph' | 'teleprac' | 'virtual'
  directNonDirect: string; // 'PR' | 'NPR'
  roomName: string | null;
  comments: string | null;
  medicaidFlag: 0 | 1;
  licenseAttestUserId: number;
  licenseAttestName: string | null;
  licenseAttestDate: string;
  medicaidMinutes: number | null;
  logs: {
    comment: string | null;
  }[];
  TPO: {
    id: number;
    targetId: number;
    target: string;
    targetFullName?: string;
    targetOther: string;
    concernTreatmentId: number;
    concernTreatment: string;
    concernTreatmentOther: string;
    practiceId: number;
    practiceOther: string;
    practiceElement: string;
    practiceFullName?: string;
    outcomeId: number;
    notes: string;
  }[];
  BIMAS: {
    id: number;
    measureCategoryCode: string;
    measureId: number;
    measureOther: string;
    score: string;
  }[];
};

export type SessionLogDetail = {
  sessionId: number;
  studentId: number;
  hStudentId: number;
  providerUserId: number;
  providerHUserId: number;
  providerId: number;
  // 'sbbhtiered' | 'unsched' | 'service'
  supportType: string;
  supportTypeId: number | null;
  supportId: number;
  sessionDate: string /**<Date (YYYY-MM-DD)> */;
  sessionTime: string; // Time: "HH:mm:ss"
  sessionMinutes: number;
  sessionStart: string; // '2021-10-12T10:00:00'
  sessionEnd: string; //'2021-10-12T10:30:00'
  studentAbsentFlag: boolean;
  studentAbsentReason: string | null;
  contactType: string; //'INDIV'
  contactTypeName: string; // 'Individual'
  sessionType: string; // 'WALKIN' || 'SCHED'
  sessionTypeName: string; // 'Walk-in' || 'Scheduled'
  referredBy: string | null;
  referredByOther: string | null;
  groupSize: number | null;
  locationId: number;
  locationDetails: string;
  sessionLocationCode: string;
  roomName: number | null;
  deliveryMode: string; //'virtual'
  directNonDirect: string; // 'PR' | 'NPR'
  directNonDirectName: string;
  comments: string | null;
  supportTypeName: string;
  createdLogId: string | number | null;
  updatedLogId: string | number | null;
  studentFirstName: string;
  studentLastName: string;
  studentExtId: string;
  grade: string;
  schoolCode: string;
  birthDate: string; //'2007-09-12T00:00:00'
  gender: string;
  studentSchoolName: string;
  locationName: string;
  planTypeName: string;
  serviceType: string;
  frequency: string;
  serviceStartDate: string; // '2021-02-02T00:00:00'
  serviceEndDate: string; // '2022-02-01T00:00:00'
  serviceActiveFlag: boolean;
  serviceEsy: boolean | null;
  schoolYear: number;
  providerFullName: string;
  providerPositionName: string;
  providerCredential: string;
  providerCredentialType: string;
  providerCredentialName: string;
  createdDate: string | null;
  createdName: string | null;
  createdPosition: string | null;
  updatedDate: string | null;
  updatedName: string | null;
  updatedPosition: string | null;
  canEdit: boolean;
  licenseAttestUserId: number | null;
  licenseAttestDate: string | null;
  licenseAttestName: string | null;
  licenseAttestFlag: boolean | null;
  deliveryModeName: string; //'Virtual'
  formVersion: string;
  logs: {
    logId: number;
    sessionId: number;
    studentId: number;
    hStudentId: number;
    userId: number;
    hUserId: number;
    updatedDate: string; // '2021-10-15T22:42:35'
    updateType: number;
    comment: string | null;
    updateName: string; // 'Created'
    username: string; // 'CN=JC TestA/OU=OU1/O=HIDOE'
    fullName: string;
  }[];
  TPO: {
    id: number;
    sbbhSessionId: number;
    targetId: number;
    target: string;
    targetFullName: string;
    targetArea: string;
    targetOther: string;
    concernTreatmentId: number;
    concernTreatment: string;
    concernTreatmentOther: string;
    practiceId: number;
    practiceElement: string;
    practiceArea: string;
    practiceOther: string;
    practiceFullName: string;
    outcomeId: number;
    outcome: string;
    notes: string;
  }[];
  BIMAS: {
    id: number;
    sbbhSessionId: number;
    measureCategoryCode: string;
    measureCategory: string;
    measureId: number;
    measureOther: string;
    measure: string;
    score: string;
  }[];
};

// Search Session

// End Need Level

export type SearchSessionType = {
  totalCount: number;
  totalPage: number;
  itemsPerPage: number;
  pageNumber: number;
  items: SessionLogDetail[];
};

export type SearchSessionRequestType = {
  itemsPerPage: number;
  pageNumber: number;
  providerUserId: number;
  schoolId: number;
  studentId: number;
  supportType: string;
  from: string;
  to: string;
};

export type TargetAreaType = {
  targetId: number;
  targetAreaId: number;
  targetArea: string;
  target: string;
  otherFlag: string;
  targetFullName: string;
  targetOther: string;
  areaSortOrder: number;
  disabledFlag: string;
};

export type ConcernTreatmentType = {
  concernTreatmentId: number;
  concernTreatment: string;
  otherFlag: string;
  disabledFlag: string;
};

export type BehaviorCategoryType = {
  measureCategoryCode: string;
  measureCategory: string;
  sortOrder: number;
  deletedFlag: string;
};

export type BehaviorMeasureType = {
  measureId: number;
  measureCategoryCode: string;
  measure: string;
  deletedFlag: string;
};

export type PracticeElementType = {
  practiceId: number;
  practiceArea: string;
  practiceElement: string;
  practiceFullName: string;
  otherFlag: boolean;
  practiceOther: string;
  areaSortOrder: number;
  disabledFlag: boolean;
  formVersion?: string;
};

export type OutComeType = {
  outcome: string;
  outcomeid: number;
  sortOrder: number;
  disabledFlag: boolean;
};

export type OptionSelectSLType = {
  value: string;
  name: string;
  sortOrder: number | null;
  disabledFlag?: boolean;
};

export type sessionOptionsSelectSLType = {
  value: string;
  name: string;
  sortOrder: number | null;
  disabledFlag: boolean;
};

export type getProviderHUserIdType = {
  providerUserId: number;
};

export type ProviderHUserId = {
  provider_h_user_id: number;
};

export type ProviderId = {
  provider_id: number;
};

export type getSupportTypeIdParams = {
  supportId: number;
  supportType: string;
};

export type SupportTypeId = {
  support_type_id: number;
};

export type getSLHeaderParams = {
  sessionId: number;
  supportType: string;
  supportId: number;
};

export type SLHeader = {
  studentId: number;
  supportType: string;
  supportTypeName: string | null;
  supportId: number;
  supportActiveFlag: boolean | null;
  targetArea: string;
  esyFlag?: boolean | null;
  frequency: string | null;
  studentFirstName: string;
  studentLastName: string;
  studentExtId: string;
  grade: string;
  schoolCode: string;
  birthDate: string;
  gender: string;
  studentSchoolName: string;
  serviceEsy: boolean | null;
  supportStartDate: string;
  supportEndDate: string;
};
